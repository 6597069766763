
.toggle-visibility {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6c757d;
}

.toggle-visibility:hover {
    color: #224EE5;
}
